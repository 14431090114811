/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import ComprehensiveSolutionsimg from '../../../assets/images/icons/ComprehensiveSolutions.png';
import Expertiseimg from '../../../assets/images/icons/Expertise.png';
import peaceofmindimg from '../../../assets/images/icons/peace-of-mind.png';
import proactiveimg from '../../../assets/images/icons/proactive.png';
import scalableimage from '../../../assets/images/icons/Scalable-Services.png';
import supprtimg from '../../../assets/images/icons/support.png';
import banner from '../../../assets/images/form-banners/banner1/banner-img-2.gif';

const BannerComponent = () => {
    return (
        <div>
            <div className="static-slider10">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6" className="align-self-center text-center">
                            <span className="label label-rounded label-inverse">Always Available</span>
                            <h1 className="title">Comprehensive IT Support Services in the UK | Maestros Technical Services</h1>
                            <h6 className="subtitle op-8">
                                Enhancing your peace of mind, we provide comprehensive IT solutions—from network security setups and antivirus software installation to dedicated remote support services—ensuring your cybersecurity needs, including virus and malware removal, are fully covered while you focus on what truly matters.
                            </h6>
                            <a className="btn btn-outline-light btn-rounded btn-md btn-arrow m-t-20" href="tel:+447365881164">
                                <span>Call Us <i className="ti-arrow-right"></i></span>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-light">
                <section>
                    <div id="banner1" className="banner spacer">
                        <Container>
                            <Row>
                                <Col lg="5" md="7" className="align-self-center">
                                    <h2 className="title font-bold">Welcome to Maestros Technical Services Limited</h2>
                                    <p className="m-t-40 m-b-30">
                                    We offer comprehensive antivirus software, installation support, and services, including cybersecurity, VoIP, network assessments, and virus and malware removal, to provide businesses and individuals with peace of mind regarding their technology. Our team of experts ensures your systems run smoothly with solutions such as antivirus installation, PC cleaning and boosting, maintenance, and data recovery services.                                    </p>
                                </Col>
                                <Col lg="6" md="5" className="align-self-center ms-auto">
                                    <img src={banner} alt="IT Support and Cybersecurity Services in the UK" className="img-fluid" />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </div>
            <div className="bg-light spacer feature20 up">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title font-bold">Why Choose Us</h2>
                            <h6 className="subtitle">
                                Expertise you can trust, comprehensive IT solutions, proactive support, peace of mind, customer-centric approach, and scalable services tailored to your needs. We ensure your network security setup is robust and reliable, while our PC maintenance services keep your systems in top shape. Our remote support services and data recovery solutions further enhance your IT resilience.
                            </h6>
                        </Col>
                    </Row>
                    <Row className="wrap-feature-20">
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20">
                                                <img src={Expertiseimg} width="70" className="rounded" alt="Expertise You Can Trust" />
                                            </div>
                                            <div>
                                                <h5 className="font-medium">Expertise You Can Trust</h5>
                                                <p>Reliable IT support from experienced professionals, including network security setup and antivirus software installation.</p>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20">
                                                <img src={ComprehensiveSolutionsimg} width="70" className="rounded" alt="Comprehensive Solutions" />
                                            </div>
                                            <div>
                                                <h5 className="font-medium">Comprehensive Solutions</h5>
                                                <p>Wide range of IT services tailored to your needs, from tech support to cybersecurity and PC maintenance.</p>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20">
                                                <img src={proactiveimg} width="70" className="rounded" alt="Proactive Support" />
                                            </div>
                                            <div>
                                                <h5 className="font-medium">Proactive Support</h5>
                                                <p>Continuous monitoring to prevent disruptions, ensuring our support system your network security and PC maintenance are always up to date.</p>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20">
                                                <img src={peaceofmindimg} width="70" className="rounded" alt="Peace of Mind" />
                                            </div>
                                            <div>
                                                <h5 className="font-medium">Peace of Mind</h5>
                                                <p>Focus on business while we handle IT, including antivirus software support, proactive network security, and comprehensive PC maintenance.</p>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20">
                                                <img src={supprtimg} width="70" className="rounded" alt="Customer-Centric Approach" />
                                            </div>
                                            <div>
                                                <h5 className="font-medium">Customer-Centric Approach</h5>
                                                <p>Personalized service focused on your needs including antivirus software installation,Maestros ensuring your Remote systems are secure and well-maintained.</p>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20">
                                                <img src={scalableimage} width="70" className="rounded" alt="Scalable Services" />
                                            </div>
                                            <div>
                                                <h5 className="font-medium">Scalable Services</h5>
                                                <p>Flexible solutions that grow with your business, ensuring your IT support, including PC maintenance and data recovery, evolves with your needs.</p>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default BannerComponent;
