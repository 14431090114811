import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';

// Core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

import MalwareRemoval from '../../../assets/images/services/Virus-Malware-Removal/Malware-Removal.png';
import VirusRemoval from '../../../assets/images/services/Virus-Malware-Removal/virus-spyware-removal.png';
import SystemSecurityEnhancement from '../../../assets/images/services/Virus-Malware-Removal/System-Security-Enhancement.png';
import PCProtection from '../../../assets/images/services/Virus-Malware-Removal/Protect-Your-Systems-with-Maestros.png';
import ExpertInServices from '../../../assets/images/services/Virus-Malware-Removal/Expert-Virus-and-Malware-Removal-Solutions.png';
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WinClean_pc_cleaner from '../../../assets/images/products/Winclean-removebg-1950x1950.png';
// SEO Elements
export const seo = {
    title: "Expert Virus and Malware Removal Services | Maestros",
    description: "Protect your systems with Maestros' comprehensive virus and malware removal services. Expert assistance for individuals and businesses in the UK.",
};

const VirusMalwareRemoval = () => {
    return (
        <div id="main-wrapper">
            <Helmet>
                <title>Virus and Malware Removal | Maestros Technical Services</title>
                <meta name="description" content="Expert virus and malware removal services to protect your systems. Contact Maestros for comprehensive IT solutions in the UK." />
                <meta name="keywords" content="virus removal, malware removal, IT support, cybersecurity, UK" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Virus and Malware Removal | Maestros Technical Services" />
                <meta property="og:description" content="Get professional virus and malware removal services from Maestros. Protect your systems with our expert IT solutions designed for individuals and businesses." />
                <meta property="og:image" content="https://www.mtechsecurity.com/assets/images/services/Virus-Malware-Removal/Malware-Removal.png" />
                <meta property="og:url" content="https://www.mtechsecurity.com/virus-malware-removal" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Virus and Malware Removal | Maestros Technical Services" />
                <meta name="twitter:description" content="Secure your systems with Maestros' expert virus and malware removal services. Protect your devices from threats with our reliable IT solutions." />
                <meta name="twitter:image" content="https://www.mtechsecurity.com/assets/images/services/Virus-Malware-Removal/Malware-Removal.png" />
            </Helmet>
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="virus-malware-removal-cover" id="virus-malware-removal">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="8">
                                    <div>
                                        <h1 className="title text-white font-bold">Expert Virus and Malware Removal Services</h1>
                                        <h5 className="subtitle font-light text-white">
                                            Protect your systems with Maestros' comprehensive virus and malware removal services. Our expert assistance is tailored for individuals and businesses across the UK.
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Our Virus and Malware Removal Services</h2>
                                        <h6 className="subtitle">Rely on our expertise to keep your systems safe and secure in the UK.</h6>
                                    </Col>
                                </Row>
                                <Row className="m-t-40">
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={VirusRemoval} alt="Professional Virus Removal Services by Maestros" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Virus Removal</h5>
                                                <p className="m-b-0 font-14">
                                                    Our team specializes in swiftly removing viruses from your systems across the UK. We utilize advanced tools to detect and eliminate threats, ensuring your computer or network is restored to optimal performance without any lingering issues.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={MalwareRemoval} alt="Comprehensive Malware Removal Services by Maestros" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Malware Removal</h5>
                                                <p className="m-b-0 font-14">
                                                    Our comprehensive malware removal services are designed to protect your systems from harmful software. We conduct thorough scans to identify and eliminate malware, restoring your device’s security and functionality.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={SystemSecurityEnhancement} alt="System Security Enhancement Services" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">System Security Enhancement</h5>
                                                <p className="m-b-0 font-14">
                                                    In addition to virus and malware removal, we offer system security enhancement services. Our experts provide security assessments and implement measures to safeguard your systems against future threats, ensuring long-term protection.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="bg-light">
                            <section>
                                <div id="banner1" className="banner spacer">
                                    <Container>
                                        <Row>
                                            <Col lg="5" md="7" className="align-self-center">
                                                <h2 className="title font-bold">Protect Your Systems with Maestros</h2>
                                                <p className="m-t-40 m-b-30">
                                                    Our virus and malware removal services provide immediate relief from malicious threats. We understand the potential impact of malware on your operations. Our skilled technicians utilize cutting-edge tools and methodologies to ensure that your systems are clean and secure. From initial diagnosis to full remediation, we are with you every step of the way.
                                                </p>
                                            </Col>
                                            <Col lg="6" md="5" className="align-self-center ms-auto">
                                                <img src={PCProtection} alt="Protect Your Systems with Maestros" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </section>
                        </div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Swift and Reliable Virus and Malware Removal</h2>
                                        <h6 className="subtitle">At Maestros, we prioritize your security in the UK.</h6>
                                        <p>
                                            Our dedicated team is here to assist you in removing viruses and malware efficiently. With our expertise and advanced tools, we ensure that your systems are secure and free from threats, allowing you to operate with confidence.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        <Container>
                            <hr />
                            <Row className="m-t-40">
                                <Col md="5" className="d-flex justify-content-center align-items-center">
                                    <div className="text-center">
                                        <img 
                                            src={WinClean_pc_cleaner} 
                                            alt="WinClean PC Cleaner - System Optimization Tool" 
                                            className="product-img img-rounded" 
                                            style={{ maxWidth: '100%', height: 'auto' }} 
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <p></p>
                                    <h2>WinClean PC Cleaner</h2>
                                    <p><strong>Your complete solution for system optimization, ensuring speed, security, and reliability.</strong></p>        
                                    <div className="features-list">
                                        <ul className="list-checkmark list-skin-green">
                                            {[
                                                { text: 'Boost internal speed', description: 'Optimize system performance for a faster experience.' },
                                                { text: 'Remove malware and viruses', description: 'Keep your system safe from harmful threats.' },
                                                { text: 'Full system scan', description: 'Comprehensive checks for optimal system health.' },
                                                { text: 'Full diagnostics', description: 'Identify issues and improve performance with detailed diagnostics.' },
                                                { text: 'Repair Windows corrupt registry', description: 'Restore your system\'s integrity and stability.' },
                                                { text: 'Improve keyboard and mouse speed', description: 'Enhance your productivity with responsive controls.' },
                                                { text: 'Junk removal', description: 'Keep your system clean and clutter-free.' },
                                                { text: 'Temporary file removal', description: 'Clear out unnecessary files for better performance.' },
                                            ].map((item, index) => (
                                                <li key={index}>
                                                    <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                                                    &nbsp;{item.text}
                                                    <div className="feature-description">
                                                        {item.description}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <hr />
                                    <div>
                                        <a 
                                            className="btn bg-success-gradiant btn-arrow btn-block" 
                                            href="https://winclean.mtechsecurity.com/"
                                        >
                                            Free Download
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                        <div className="spacer bg-light">
                            <Container className="feature30">
                                <Row>
                                    <Col lg="5">
                                        <img src={ExpertInServices} className="rounded img-responsive" alt="Expert Virus and Malware Removal Solutions" />
                                    </Col>
                                    <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                        <Card className="card-shadow">
                                            <CardBody>
                                                <div className="p-20">
                                                    <span className="label label-info label-rounded">Your Security Partner</span>
                                                    <h3 className="title">Expert Virus and Malware Removal Solutions</h3>
                                                    <p>
                                                        At Maestros, we offer specialized virus and malware removal services to keep your systems safe. Our team utilizes the latest technologies to eliminate threats and enhance your system's security, giving you peace of mind.
                                                    </p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className="mini-spacer bg-success text-white c2a7">                        
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Need urgent assistance? Connect with us on +44 73658 811 64</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Explore Our Virus and Malware Removal Solutions</h1>
                                    <h6 className="subtitle">
                                        At Maestros, we specialize in comprehensive virus and malware removal services, ensuring your systems are protected from threats and vulnerabilities. 
                                        <br />
                                        Regain control of your digital environment with our expert assistance. Ready to secure your devices? Sign up for a free assessment today!
                                    </h6>
                                    <Button color="primary" className="mt-3" onClick={() => { /* Add your free assessment link or function here */ }}>
                                        Start Your Free Assessment
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default VirusMalwareRemoval;
